import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/wp-admin',
      name: 'wp-admin',
      component: () => import('../views/WpAdmin.vue')
    },
    {
      path: '/segnala',
      name: 'issuereport',
      component: () => import('../views/IssueReport.vue')
    },
    {
      path: '/menu',
      name: 'menu',
      component: () => import('../views/MenuView.vue')
    },
    {
      path: '/programma',
      name: 'programma',
      component: () => import('../views/ProgrammaView.vue')
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('../views/NewsView.vue')
    },
    {
      path: '/passate-edizioni',
      name: 'passate-edizioni',
      component: () => import('../views/PassateEdizioniView.vue')
    },
    {
      path: '/galleria',
      name: 'galleria',
      component: () => import('../views/StandaloneGalleryView.vue')
    },
    {
      path: '/galleria-wp',
      name: 'galleria-wp',
      component: () => import('../views/GalleriaView.vue')
    },
    {
      path: '/news/:id',
      name: 'news-read',
      component: () => import('../views/PostView.vue')
    },
    {
      path: '/regolamenti',
      name: 'regolamenti',
      component: () => import('../views/RegolamentiView.vue')
    },
    {
      path: '/regolamenti/:id',
      name: 'regolamento-view',
      component: () => import('../views/RegolamentoView.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'page-not-found',
      component: () => import('../views/PageNotFound.vue')
    }
  ]
})


router.beforeEach((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
})

router.afterEach((to, from) => {
  NProgress.done();
})

export default router

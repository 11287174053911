<script setup>
import MenuIcon from 'vue-material-design-icons/Menu.vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';
</script>

<template>
    <div class="fpnavbar bg-1">
        <RouterLink to="/" class="fpheader">
            <div class="text-center bg-campanile">
                <h2>Fieste in Paîs</h2>
            </div>
        </RouterLink>
    </div>
    <ul class="fpnavbar d-none d-md-flex fpnavbar-desktop bg-1 nav nav-pills justify-content-center sticky-top" style="padding-bottom: 10px; padding-top: 10px;">
        <li class="nav-item" v-for="item in navbarItems">
            <RouterLink v-if="$route.name && item.visible" class="nav-link" :class="{active: $route.name.startsWith(item.routeName)}" :to="item.route">{{ item.label }}</RouterLink>
        </li>
    </ul>
    <div class="fpnavbar d-md-none bg-1 nav nav-pills justify-content-center sticky-top" style="padding-bottom: 10px; padding-top: 10px;">
        <button class="btn btn-primary text-white" style="width: 90%; margin: auto;" data-bs-toggle="offcanvas" data-bs-target="#menuOffcanvas" aria-controls="menuOffcanvas">
            <MenuIcon />
        </button>
    </div>

    <div class="nav-offcanvas offcanvas offcanvas-end bg-1 text-white" data-bs-theme="dark" tabindex="-1" id="menuOffcanvas" aria-labelledby="menuOffcanvasLabel">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="menuOffcanvasLabel"><MenuIcon /> Menu</h5>
            <button class="btn btn-secondary" data-bs-dismiss="offcanvas" aria-label="Chiudi"><CloseIcon /></button>
        </div>
        <div class="offcanvas-body">
            <ul class="nav nav-pills flex-column">
                <li class="nav-item" v-for="item in navbarItems" data-bs-dismiss="offcanvas">
                    <RouterLink v-if="$route.name && item.visible" class="nav-link" :class="{active: $route.name.startsWith(item.routeName)}" :to="item.route">{{ item.label }}</RouterLink>
                </li>
            </ul>
        </div>
    </div>
</template>

<style>
    .bg-campanile {
        background-image: url("../assets/campanile.png");
        background-position: center;
        padding-top: 20px;
        padding-bottom: 20px;
        color: white;
        background-size: contain;
        background-repeat: no-repeat;
    }
</style>

<script>
    export default {
        data() {
            return {
                navbarItems: [
                    {
                        label: "Home",
                        route: "/",
                        routeName: "home",
                        visible: true
                    },
                    {
                        label: "Menù",
                        route: "/menu",
                        routeName: "menu",
                        visible: true
                    },
                    {
                        label: "Programma",
                        route: "/programma",
                        routeName: "programma",
                        visible: true
                    },
                    {
                        label: "News",
                        route: "/news",
                        routeName: "news",
                        visible: true
                    },
                    {
                        label: "Passate edizioni",
                        route: "/passate-edizioni",
                        routeName: "passate-edizioni",
                        visible: true
                    },
                    {
                        label: "Galleria",
                        route: "/galleria",
                        routeName: "galleria",
                        visible: true
                    }
                ]
            }
        }
    }
</script>
<script setup>
import LoadingSpinner from '../components/LoadingSpinner.vue';
</script>

<template>
  <div class="pageContent">
    <div class="row">
      <div class="col-lg-9 col-md-8 col-12">
        <div class="row">
          <div class="col-12 col-lg-1 col-xl-2"></div>
          <div class="col-12 col-lg-10 col-xl-8">
            <div class="card fp-card mb-2 w-100">
              <img src="https://wp.fiesteinpais.it/wp-content/uploads/2022/09/Copia-di-fb-1024x573.png" style="border-radius: var(--bs-border-radius);">
            </div>
          </div>
          <div class="col-12 col-lg-1 col-xl-2"></div>
        </div>
        <div class="card card-body fp-card mb-2">
          <h4 class="mb-3">Ultime novità</h4>
          <div v-if="!newsLoaded">
            <LoadingSpinner text="Caricamento degli articoli..." />
          </div>
          <div v-if="newsLoaded" class="row d-none d-xl-flex">
            <div class="col-3 mb-3" v-for="post in newsData.slice(0, 4)">
              <RouterLink :to='"/news/" + post.id + "?back=/"' style="text-decoration: none;">
                <div class="card text-bg-dark" style="height: 100%; max-height: 256px; overflow: hidden;">
                  <img :src="post.image" class="card-img" :alt="post.title" style="object-fit: cover; width: 100%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                  <div class="card-body">
                    <p class="card-text">{{ post.title }}</p>
                  </div>
                </div>
              </RouterLink>
            </div>
          </div>
          <div v-if="newsLoaded" class="row d-xl-none">
            <div class="col-lg-4 col-12 mb-3" v-for="post in newsData.slice(0, 3)">
              <RouterLink :to='"/news/" + post.id + "?back=/"' style="text-decoration: none;">
                <div class="card text-bg-dark" style="height: 100%; max-height: 256px;">
                  <img :src="post.image" class="card-img" :alt="post.title" style="object-fit: cover; width: 100%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                  <div class="card-body">
                    <p class="card-text">{{ post.title }}</p>
                  </div>
                </div>
              </RouterLink>
            </div>
          </div>
          <RouterLink to="/news" class="btn btn-primary w-100">Tutti gli articoli</RouterLink>
        </div>
        <div class="card card-body fp-card">
          <RouterLink to="/regolamenti" class="btn btn-primary w-100">Regolamenti</RouterLink>
        </div>

        <div class="card fp-card mt-2 mb-4" style="height: 512px;">
          <LoadingSpinner v-if="!mapFrameLoaded" text="Caricamento della mappa..." />
          <iframe @load="mapFrameLoaded = true" :class="mapFrameLoaded ? '' : 'd-none'" style="height: 512px; border-radius: var(--bs-border-radius);" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1733.6488393048644!2d13.189276952407559!3d46.069618912186804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477a35d139b9a649%3A0xa403f28811ccab32!2sCortile%20Parrocchiale%20Passons!5e1!3m2!1sen!2sit!4v1657560683042!5m2!1sen!2sit" allowfullscreen="allowfullscreen"></iframe>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-12">
        <div class="fpHomeIcon mb-2">
          <img src="../assets/campanile.png" class="w-100">
        </div>
        <div class="card fp-card">
          <div class="card-body">
            <h5 class="card-title">A Passons</h5>
            <p class="card-text" style="font-size: 85%;">
              Come omp che sot sere, poiât il falcét<br>e in tal grin dal rivâl al fâs polse,<br>si ciate Passons, paîs de me int<br><br>I bràs a bradòn, van cirint seneôs<br>des Blacis il fresch, e il vert<br>des cisis de val dal Cormôr<br><br>Se nol fos par chel puint,<br>che no duc' o vin ciâr<br>cittadin dal Cjstiel tu saressis<br>fasînt cove di villis e di ciarandis<br><br>Le smànie di vivi, nus puarte lontan<br>ma quant che il crepuscul de vite<br>di merits e colpis vorrà fa l'addizòn,<br>sarai lì, all'ombre dal to ciampanili<br>par gioldimi l'ultim respîr,<br>Passons paîs de me int.<br>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '../components/LoadingSpinner.vue';

export default {
    data() {
        return {
            newsLoaded: false,
            mapFrameLoaded: false,
            newsData: []
        };
    },
    created: function () {
        fetch("https://wp.fiesteinpais.it/wp-json/fiesteinpais/v1/posts")
            .then(r => r.json())
            .then(json => {
            this.newsData = json;
            console.log("[fiesteinpais] [frontend] Successfully got newslist.");
            this.newsLoaded = true;
        });
    },
    components: { LoadingSpinner }
}
</script>
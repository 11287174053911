<template>
    <div v-if="vexpand" class="v-center text-center">
        <div class="spinner-border text-light" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <h5>{{ text }}</h5>
    </div>
    <div v-if="!vexpand" class="text-center" style="padding-top: 75px; padding-bottom: 75px;">
        <div class="spinner-border text-light" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <h5>{{ text }}</h5>
    </div>
</template>

<script>
export default {
    props: ['text', 'vexpand']
}
</script>
<template>
    <div v-if="loaded" class="sponsorScroller">
        <a v-for="sponsor in currentSponsors" class="sponsorLogo" :href="sponsor.href" target="_blank">
            <img :src="sponsor.logo" :alt="sponsor.alt" :style="sponsor.style">
        </a>
    </div>
</template>

<style scoped>
.sponsorScroller {
    max-width: 900px;
    padding-left: 10px;
    padding-right: 10px;
    margin: auto;
    overflow: hidden;
    white-space: nowrap;
}
.sponsorLogo {
    display: inline-block;
    margin-right: 15px;
}
.sponsorLogo img {
    height: 128px;
}
</style>

<script>
export default {
    data() {
        return {
            sponsors: [],
            currentSponsors: this.sponsors,
            loaded: false
        }
    },
    methods: {
        setScrollerScrollPositionInitial: function() {
            try {
                document.getElementsByClassName("sponsorScroller")[0].scrollLeft = 0;
            } catch {
                setTimeout(this.setScrollerScrollPositionInitial, 100);
            }
        },
        selectNextLogo: function() {
            let el = this.currentSponsors[0];
            let len = this.currentSponsors.length;
            this.currentSponsors.splice(0, 1);
            this.currentSponsors.splice(len - 1, 0, el)
        },
        scrollLogos: function() {
            try {
                document.getElementsByClassName("sponsorScroller")[0].scrollLeft += 1;
                if (document.getElementsByClassName("sponsorScroller")[0].scrollLeft > document.getElementsByClassName("sponsorLogo")[0].clientWidth + 15) {
                    this.setScrollerScrollPositionInitial();
                    let el = this.currentSponsors[0];
                    let len = this.currentSponsors.length;
                    this.currentSponsors.splice(0, 1);
                    this.currentSponsors.splice(len - 1, 0, el);
                }
                setTimeout(this.scrollLogos, 50);
            }
            catch {
                setTimeout(this.scrollLogos, 50);
            }
        }
    },
    mounted: function() {
        fetch("https://wp.fiesteinpais.it/wp-json/fiesteinpais-galleries/v1/sponsors")
            .then(response => response.json())
            .then(response => {
                this.sponsors = response;
                this.currentSponsors = this.sponsors;
                this.loaded = true;
                this.setScrollerScrollPositionInitial();
                this.scrollLogos();
            })
    }
}
</script>
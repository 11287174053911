import './assets/nprogress/nprogress.css'

import './styles/main.scss'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue"

import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap

import './assets/nprogress/nprogress.js'

const app = createApp(App)

app.use(router)

Sentry.init({
    app,
    dsn: "https://9f25ba00a431f80964d5b8138a162d62@o1343202.ingest.sentry.io/4505619481821184",
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: ["fiesteinpais.it", "beta.fiesteinpais.it", /^\//],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
        ],
    tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Fix modals going berserk when the current route is popped
window.addEventListener("popstate", function(e) {
    // Check if a modal is open
    if (document.body.classList.contains("modal-open")) {
        // Destroy whatever remains of the modal that was not already destroyed by Vue on route change
        document.getElementsByClassName("modal-backdrop")[0].remove()
        document.body.classList.remove("modal-open")
        document.body.setAttribute("style", "")
        
        // Try to not confuse the user by returning them to the page they were in
        e.preventDefault()
        history.go(1)
    }
})

app.mount('#app')